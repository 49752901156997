
import React from 'react';
import list_1 from '../landing/icons/list_1.svg'
import list_2 from '../landing/icons/list_2.svg'
import list_3 from '../landing/icons/list_3.svg'
import list_4 from '../landing/icons/list_4.svg'
import instruction_1 from '../landing/icons/instruction_1.svg'
import instruction_2 from '../landing/icons/instruction_2.svg'
import instruction_3 from '../landing/icons/instruction_3.svg'
import instruction_4 from '../landing/icons/instruction_4.svg'
import buy_like from '../landing/icons/buy_like.svg'
import buy_comment from '../landing/icons/buy_comment.svg'
import buy_user from '../landing/icons/buy_user.svg'
import buy_view from '../landing/icons/buy_view.svg'
import star_left from '../landing/icons/star_left.svg'
import star_right from '../landing/icons/star_right.svg'
import EarnPlashka from '../landing/EarnPlashka';

  import { getLocalizedLandingStrings } from './LocalizedLanding';
  
    var strings = getLocalizedLandingStrings()
    
    class HowItWorks extends React.Component {
        constructor(props){
            super(props)
            this.state = {
                currentClicked : 1
            }
        }
        on1Click = () => {
            this.setState({currentClicked : 1})
        }
        on2Click = () => {
            this.setState({currentClicked : 2})
        }
        on3Click = () => {
            this.setState({currentClicked : 3})
        }
        on4Click = () => {
            this.setState({currentClicked : 4})
        }
        render(){
                return (
                        <div className="section-about back-section section_div" id="how-it-works">
                            <div className="container">
                                <div className="how-it-works-container" >
                                    <div className="it_works_container">
                                        <div className="intro-text text-left">
                                            <p className="it_works_text">{strings.HOW_IT_WORKS_TITLE}</p>
                                            <div onClick={this.on1Click} id="flex-text-1" className={this.state.currentClicked == 1 ? "flex-text flex-text-active" : "flex-text"}>
                                                <img className="img-list-number" src={list_1}/>
                                                <p className="list-text"><span onClick={this.props.openAuth} id="authorize" className="sign_color">{strings.SIGN_IN}</span> {strings.SIGN_IN_2}</p>
                                            </div>
                                            <div onClick={this.on2Click} id="flex-text-2" className={this.state.currentClicked == 2 ? "flex-text flex-text-active" : "flex-text"}>
                                                <img className="img-list-number" src={list_2}/>
                                                <p className="list-text">{strings.CHOOSE_WHAT_YOU_WANT}</p>
                                            </div>
                                            <div onClick={this.on3Click} id="flex-text-3" className={this.state.currentClicked == 3 ? "flex-text flex-text-active" : "flex-text"}>
                                                <img className="img-list-number" src={list_3}/>
                                                <p className="list-text">{strings.WATCH_POPULARITY}</p>
                                            </div>
                                            <div onClick={this.on4Click} id="flex-text-4" className={this.state.currentClicked == 4 ? "flex-text flex-text-active" : "flex-text"}>
                                                <img className="img-list-number" src={list_4}/>
                                                <p className="list-text">{strings.RUN_OUT_COINS}</p>
                                            </div>
                                            <button onClick={this.props.openAuth} className="wrap-account btn top-32 left_70" type="button" name="button">{strings.WRAP_ACC_BTN}</button>
                                        </div>
                                    </div>
                                    <img title={strings.IMAGE_1_TITLE} className="image-instruction" src={this.state.currentClicked == 4 ? instruction_4 : this.state.currentClicked == 3 ? instruction_3 : this.state.currentClicked == 2 ? instruction_2 : instruction_1} alt={strings.IMAGE_1_TITLE}/>
                                </div>
                                <div className="how-it-works-container-mobile">
                                    <p className="mobile-how-works-title">{strings.HOW_IT_WORKS_TITLE}</p>
                                    <div onClick={this.on1Click} className="flex-text">
                                        <img className="img-list-number" src={list_1}/>
                                        <p className="list-text"><span className="sign_color">{strings.SIGN_IN}</span> {strings.SIGN_IN_2}</p>
                                    </div>
                                    <div onClick={this.on2Click} className="flex-text">
                                        <img className="img-list-number" src={list_2}/>
                                        <p className="list-text">{strings.CHOOSE_WHAT_YOU_WANT}</p>
                                    </div>
                                    <div onClick={this.on3Click} className="flex-text">
                                        <img className="img-list-number" src={list_3}/>
                                        <p className="list-text">{strings.WATCH_POPULARITY}</p>
                                    </div>
                                    <div onClick={this.on4Click} className="flex-text">
                                        <img className="img-list-number" src={list_4}/>
                                        <p className="list-text">{strings.RUN_OUT_COINS}</p>
                                    </div>
                                </div>

                                <div className="earn-money-section">
                                    <p className="earn-money-top-text">{strings.NO_TIME}</p>
                                    <div className="container-flex-just-cont">
                                        <EarnPlashka imageTitle={strings.ICON_1_TITLE} image={buy_like} text={strings.LIKES} description={strings.LIKES_DESC}/>
                                        <EarnPlashka imageTitle={strings.ICON_2_TITLE} image={buy_comment} text={strings.COMMENTS} description={strings.COMMENTS_DESC}/>
                                        <EarnPlashka imageTitle={strings.ICON_3_TITLE} image={buy_user} text={strings.FOLLOWERS} description={strings.FOLLOWERS_DESC}/>
                                        <EarnPlashka imageTitle={strings.ICON_4_TITLE} image={buy_view} text={strings.VIEWS} description={strings.VIEWS_DESC}/>
                                    </div>
                                </div>
                                <div className="stars-wrap-acc">
                                    <img className="left-stars" src={star_left}/>
                                    <div className="stars-text">
                                        <p>{strings.GET_20_COINS}</p>
                                        <button onClick={this.props.openAuth} className="wrap-account btn top-32 width_50" type="button" name="button">{strings.WRAP_ACC_BTN}</button>
                                    </div>
                                    <img className="right-stars" src={star_right}/>
                                </div>
                                <div className="stars-wrap-acc-mobile">
                                    <img className="left-stars" src={star_left}/>
                                    <p className="stars-text-mobile">{strings.GET_20_COINS_2}<br/>{strings.GET_20_COINS_3}</p>
                                    <img className="right-stars" src={star_right}/>
                                </div>
                            </div>
                        </div>
                ); 
            
            
        }
    }
    export default HowItWorks;
    