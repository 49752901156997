import LocalizedStrings from 'react-localization'
export const getLocalizedLandingStrings = () => {
    let strings = new LocalizedStrings({
        ru:{
            ABOUT_US: "О НАС",
            HOW_IT_WORKS: "КАК ЭТО РАБОТАЕТ",
            REVIEWS: "ОТЗЫВЫ",
            PROMOTE_MY_ACCOUNT: "РАСКРУТИТЬ МОЙ ИНСТАГРАМ",
            WRAP_INSTAGRAM: "Раскрутить инстаграм",
            HOW_IT_WORKS_TITLE: "Накрутка Инстаграм бесплатно — как это работает",
            WRAP_ACC_BTN: "Накрутить Инстаграм",
            OTHER_SERVICES: "Накрутка ВК, Тик Ток, Яндекс.Карты, Авито и многое другое",
            BLOG_TITLE: "Наш блог — советы по продвижению",
            PROMOTE_BTN: "Раскрутить",

            GET_LIKES: "Накрутка лайков, подписчиков, комментариев и просмотров в инстаграм.",
            JUST_CHOOSE: "Просто выберите фотографии и видео, которые хотите накрутить и получите быстрое продвижение в инстаграм.",



            FREE: "Бесплатная накрутка инстаграм",
            FREE_DESC: "Накрутка лайков бесплатно, также вы всегда можете накрутить подписчиков, комментарии и просмотры в Инстаграм абсолютно бесплатно в обмен на свои лайки.",
            REAL_USERS: "Подписчики инстаграм",
            REAL_USERS_DESC: "На вас подпишутся реальные подписчики в инстаграме, которые заинтересованы в выполнении ваших заданий.",
            NO_REGISTRATION: "Накрутка инстаграм без регистрации",
            NO_REGISTRATION_DESC: "Просто авторизуйтесь в сервисе при помощи данных вашего аккаунта в Инстаграм.",
            NO_REGISTRATION_DESC_2: "Сервис доступен на любых видах устройств.",

            PROMOTE_MULTIPLE: "Раскрутка нескольких аккаунтов Инстаграм",
            PROMOTE_MULTIPLE_DESC: "Наш сервис позволяет раскручивать сразу несколько аккаунтов Инстаграм и\u00A0удобно переключаться между ними.",
            LOCATION_GENDER: "Таргетинг по полу, возрасту и\u00A0местоположению (скоро!)",
            LOCATION_GENDER_DESC: "Выберите пользователи какого пола, возраста и\u00A0из\u00A0какой страны будут выполнять ваши задания.",
            PACKAGE_OFFERS: "Пакетные предложения (скоро!)",
            PACKAGE_OFFERS_DESC: "Это стимуляция естественной активности пользователей Инстаграм. Включает лайки, просмотры видео и\u00A0комментарии.",
            PACKAGE_OFFERS_DESC_2: "Включает лайки, просмотры видео и комментарии.",

            SIGN_IN: "Авторизуйтесь",
            SIGN_IN_2: "под своим аккаунтом Instagram и получите 20 монет на раскрутку.",
            CHOOSE_WHAT_YOU_WANT: "Закажите на полученные монеты лайки, комментарии, просмотры видео или новых подписчиков. Другие пользователи нашего сервиса начнут выполнять ваш заказ, чтобы заработать монеты.",
            WATCH_POPULARITY: "Наблюдайте, как растёт популярность вашего аккаунта.",
            RUN_OUT_COINS: "Если закончились монеты, то заработайте их, выполняя заказы других пользователей. Или просто купите монеты и вложите их в раскрутку своего аккаунта.",

            NO_TIME: "Если нет времени зарабатывать монеты, просто купите их",
            LIKES: "Накрутка лайков инстаграм",
            LIKES_DESC: "От\u00A020\u00A0копеек за\u00A0штуку",
            COMMENTS: "Накрутка комментариев инстаграм",
            COMMENTS_DESC: "От\u00A01\u00A0рубля штуку",
            FOLLOWERS: "Накрутка подписчиков инстаграм",
            FOLLOWERS_DESC: "От\u00A01\u00A0рубля за\u00A0каждого",
            VIEWS: "Накрутка просмотров инстаграм",
            VIEWS_DESC: "От\u00A01\u00A0рубля за\u00A0каждый просмотр видео",

            ICON_1_TITLE : "Стоимость накрутки лайков",
            ICON_2_TITLE : "Стоимость накрутки комментариев",
            ICON_3_TITLE : "Стоимость накрутки подписчиков",
            ICON_4_TITLE : "Стоимость накрутки просмотров",

            PARTNER_1 : "А ещё у нас есть партнёрская программа",
            PARTNER_2 : "Для тех, кто хочет получить источник пассивного дохода",
            PARTNER_3 : "Узнать подробнее",

            IMAGE_1_TITLE: "Простая авторизация, ввод пароля не нужен. Дарим 20 монет на накрутку.",
            IMAGE_2_TITLE: "Заказать лайки, комментарии, просмотры видео, новых подписчиков.",
            IMAGE_3_TITLE: "Ваш аккаунт Инстаграм становится популярнее",
            IMAGE_4_TITLE: "Купите или заработайте монеты на раскрутку вашего аккаунта Инстаграм",

            GET_20_COINS: "Получи 20 монет на накрутку бесплатно прямо сейчас",
            GET_20_COINS_2: "Получи 20 монет на накрутку бесплатно",
            GET_20_COINS_3: "прямо сейчас",

            REVIEWS_TITUL: "Отзывы клиентов",
            REVIEWS_TITUL_DESC: "из нашего официального аккаунта Instagram",

            OUR_INSTAGRAM: "Мы в Instagram",
            OUR_VK: "Мы в Вк",
            SO_MANY_PEOPLE: "Столько людей уже используют наш сервис",
            CASES_OF_OUR_CLIENTS: "Кейсы наших клиентов",
            RESULT_SCREENS: "Результат за 6 месяцев",
            BEFORE: "До",
            AFTER: "После накрутки в Instarlike",

            SWIPE_RIGHT: "Листайте вправо, чтобы увидеть результаты раскрутки Instarlike",
            LICENSE: "Лицензионное соглашение",
            ADVICES_WRAP: "Советы по раскрутке",

            SERVICE_PROMOTION: 'Сервис для раскрутки и продвижения аккаунтов Instagram.',
            SERVICE_PROMOTION_2: 'Instarlike – получи подписчиков и много лайков для твоего аккаунта в Instagram',

            INSTA_PROMO_ACC: 'Продвижение в Инстаграм',
            SCREEN_1_ALT: 'Скриншот аккаунта Инстаграм нашего клиента charlie_and_watson. До накрутки было 9 подписчиков',
            SCREEN_1_TITLE: 'До накрутки было 9 подписчиков',
            SCREEN_2_TITLE: 'После накрутки через сервис Instarlike стало 66400 подписчиков',
            SCREEN_2_ALT: 'После накрутки стало 66400 подписчиков',

            REVIEW_NAME_1 : 'Юлия З.',
            REVIEW_NAME_2 : 'Андрей А.',
            REVIEW_NAME_3 : 'Игорь Л.',
            REVIEW_NAME_4 : 'Александр А.',
            REVIEW_NAME_5 : 'Владимир С.',
            REVIEW_NAME_6 : 'Артём М.',

            REVIEW_TEXT_1 : 'Впервые пользуюсь подобным сервисом, заказ был выполнен очень быстро. Я осталась довольна!',
            REVIEW_TEXT_2 : 'Мне нравится, что можно оценивать чужие фотки и зарабатывать монеты. А друзья удивляются, откуда у меня столько лайков))',
            REVIEW_TEXT_3 : 'Спасибо разработчикам за сервис! Всё честно и быстро. После накрутки подписчиков мой профиль поднялся в поиске и стало приходить много клиентов. Буду и дальше пользоваться вашими услугами!',
            REVIEW_TEXT_4 : 'Очень хорошая, быстрая и качественная накрутка. Топ сервис!',
            REVIEW_TEXT_5 : 'Разумные цены и качество на высоте!',
            REVIEW_TEXT_6 : 'Круто, что можно работать с несколькими аккаунтами одновременно. Благодаря сервису довольно быстро раскрутил свой бизнес аккаунт.',
           
            BLACK_NAVBAR_1 : 'База знаний',
            BLACK_NAVBAR_2 : 'Как накручивать',
            BLACK_NAVBAR_3 : 'Заработай с нами',
            BLACK_NAVBAR_4 : 'Раскрутка других соцсетей',
            FOLLOW_NEWS : 'Следите за новостями сервиса',
            FOLLOW_NEWS_2 : 'в наших соцсетях',
            OTHER_TITLE_1 : 'Раскрутка других соцсетей',
            OTHER_TITLE_2 : 'и трафик для любых задач',
            OTHER_TITLE_3 : 'от наших партнёров',
            FIND_MORE : 'Узнать больше',
            SERVICE_NEWS : 'Новости сервиса и поддержка',
            USER_AGREEMENT : 'Пользовательское соглашение',
            POLICY : 'Политика конфиденциальности',
            STORIES_MENU : 'Готовые сторис',
            STORIES_TITLE : 'Наш новый продукт — пакет stories на 30 дней,',
            STORIES_TITLE_2 : 'чтобы улучшить охваты и вовлечённость в твоём аккаунте',
            STORIES_TEXT : 'Готовые сторис на 30 дней без привязки к конкретному месяцу и времени года. В пакет входят: головоломки, викторины, вопросы и другие активности, которые зацепят твою аудиторию',
        },
        en : {
            ABOUT_US: "ABOUT US",
            HOW_IT_WORKS: "HOW IT WORKS",
            REVIEWS: "REVIEWS",
            PROMOTE_MY_ACCOUNT: "PROMOTE MY INSTAGRAM",
            WRAP_INSTAGRAM: "Promote Instagram",
            HOW_IT_WORKS_TITLE: "Free Instagram Promotion — How It Works",
            WRAP_ACC_BTN: "Promote Instagram",
            OTHER_SERVICES: "Promotion for VK, TikTok, Yandex.Maps, Avito and much more",
            BLOG_TITLE: "Our Blog — Promotion Tips",
            PROMOTE_BTN: "Promote",

            GET_LIKES: "Get likes, comments and views from real people as well as increase the number of followers for free.",
            JUST_CHOOSE: "Just choose the photos and videos you want to promote and watch the result.",

            FREE: "Free",
            FREE_DESC: "Get followers, likes, comments and views in Instagram absolute- ly free.",
            REAL_USERS: "Real Users",
            REAL_USERS_DESC: "We motivate real Instagrammers to like and comment your posts, follow and view your videos.",
            NO_REGISTRATION: "Without Registration",
            NO_REGISTRATION_DESC: "Just sign in using your Instagram login and password.",
            NO_REGISTRATION_DESC_2: "Instarlike is available on all kinds of devices.",

            PROMOTE_MULTIPLE: "Promote multiple accounts",
            PROMOTE_MULTIPLE_DESC: "You can promote multiple accounts. We made switching between them easy.",
            LOCATION_GENDER: "Location, gender and age targeting",
            LOCATION_GENDER_DESC: "Choose and receive likes from a user of specific gender, age or location.",
            PACKAGE_OFFERS: "Package Offers (coming soon!)",
            PACKAGE_OFFERS_DESC: "This is stimulation of natural activity of Instagram users.",
            PACKAGE_OFFERS_DESC_2: "It includes likes, views, videos and comments",

            SIGN_IN: "Sign in",
            SIGN_IN_2: "using your Instagram account and get 20 coins for promotion.",
            CHOOSE_WHAT_YOU_WANT: "Choose what you want to get: likes, comments, views or fol- lowers, and create order. Other users of Instarlike will execute you order to get coins.",
            WATCH_POPULARITY: "Watch as the popularity of your account grows.",
            RUN_OUT_COINS: "If you run out of coins, then earn them by completing orders from other users. Or just buy coins and invest them in the pro- motion of your account.",

            NO_TIME: "If You Haven't Time To Earn Coins, Just Buy Them",
            LIKES: "Likes",
            LIKES_DESC: "From 2 cents apiece",
            COMMENTS: "Comments",
            COMMENTS_DESC: "From 0.3 cents apiece",
            FOLLOWERS: "Followers",
            FOLLOWERS_DESC: "From 20 cents apiece",
            VIEWS: "Views",
            VIEWS_DESC: "From 20 cents apiece",

            GET_20_COINS: "Get 20 coins to promotion for free right now",
            GET_20_COINS_2: "Get 20 coins to promotion for free",
            GET_20_COINS_3: "right now",

            REVIEWS_TITUL: "Reviews",
            REVIEWS_TITUL_DESC: "from our official Instagram account",

            OUR_INSTAGRAM: "Our Instagram",
            OUR_VK: "Our VK",
            SO_MANY_PEOPLE: "So many people are already using our service",
            CASES_OF_OUR_CLIENTS: "Cases of our clients:",
            BEFORE: "Before",
            AFTER: "After promotion with Instarlike",

            SWIPE_RIGHT: "Scroll to the right to see the results of the promotion Instarlike",
            LICENSE: "License agreement",
            ADVICES_WRAP: "Promotion Tips",

            SERVICE_PROMOTION: 'Service for promotion Instagram profiles.',
            SERVICE_PROMOTION_2: 'Instarlike - get followers and lots of likes for your Instagram account.',

            INSTA_PROMO_ACC: 'Instagram account promotion',
            IMAGE_1_TITLE: "Simple authorization, no password is needed. We give 20 coins per registration.",
             IMAGE_2_TITLE: "Order likes, comments, video views, new followers.",
             IMAGE_3_TITLE: "Your Instagram account is becoming more popular",
             IMAGE_4_TITLE: "Buy or earn coins to promote your Instagram account",

            ICON_1_TITLE: "Cost of likes",
             ICON_2_TITLE: "Cost of getting comments",
             ICON_3_TITLE: "Followers get Cost",
             ICON_4_TITLE: "The cost of building up views",
            RESULT_SCREENS: "The result for 6 months",

            SCREEN_1_ALT: 'Screenshot of the Instagram account of our client charlie_and_watson. There were 9 followers before cheating',
             SCREEN_1_TITLE: 'There were 9 followers before the payoff',
             SCREEN_2_TITLE: 'After the promotion through Instarlike service, there were 66400 followers',
             SCREEN_2_ALT: 'After the promotion, there were 66400 followers',

            PARTNER_1: "And we also have an affiliate program",
             PARTNER_2: "For those who want a source of passive income",
             PARTNER_3: "Learn more",

            REVIEW_NAME_1: 'Julia Z.',
            REVIEW_NAME_2: 'Andrei A.',
            REVIEW_NAME_3: 'Igor L.',
            REVIEW_NAME_4: 'Alexander A.',
            REVIEW_NAME_5: 'Vladimir S.',
            REVIEW_NAME_6: 'Artyom M.',

            REVIEW_TEXT_1: 'For the first time I use this service, the order was completed very quickly. I was satisfied! ',
            REVIEW_TEXT_2: "I like that you can evaluate other people's photos and earn coins. And friends wonder where I got so many likes))",
            REVIEW_TEXT_3: 'Thanks to the developers for the service! Everything is honest and fast. After cheating subscribers, my profile went up in search and a lot of customers began to come. I will continue to use your services! ',
            REVIEW_TEXT_4: 'Very good, fast and high-quality wrapping. Top service! ',
            REVIEW_TEXT_5: 'Reasonable prices and quality on top!',
            REVIEW_TEXT_6: 'Cool that you can work with multiple accounts at the same time. Thanks to the service, he quickly promoted his business account. ',
            STORIES_MENU: 'Ready Stories',
            USER_AGREEMENT : 'User agreement',
            POLICY : 'Privacy policy',
            STORIES_TITLE: 'Our new product is a 30-day stories package,',
             STORIES_TITLE_2: 'to improve reach and engagement in your account',
             STORIES_TEXT: 'Ready stories for 30 days without reference to a specific month and time of year. The package includes: puzzles, quizzes, questions and other activities that will hook your audience ',
        }
    });
    return strings;
}